<template>
    <div class="auth">
        <div class="d-flex flex-column min-vh-100">
        <Menubar msg="test" />
          <b-modal ref="my-loading" id="modal-2" title="Loading" @hide="onHide" centered hide-footer hide-header>
            <div class="text-center">
                <b-spinner v-if="token" variant="primary" label="Text Centered"></b-spinner>
                <p></p>
                <strong v-if="!token"><a href="/" >登入過期 (請重新登入)</a></strong>
                <strong v-if="token">Loading....</strong>
            </div>
          </b-modal> 

        <div class="wrapper flex-grow-1"></div>  
        <Footbar msg="test" /> 
        </div>
    </div>
</template>
<script>
//import { tip } from "../api/utils";
import Menubar from '@/components/Menubar.vue'
import Footbar from '@/components/Footbar.vue'
//import router from '../router'
import { mapActions} from 'vuex';
export default{
    name: 'Auth',
    components: {
        Menubar,
        Footbar
    },    
    data(){
        return{
            uId:{
                String
            },
            token:{
                String
            },
             posid:{
                String
            },
            id:{
                String
            }
        }
    },
    methods:{
        ...mapActions('account',['loginline','loginfb']),
        onHide(evt) {
            if(evt.trigger === "backdrop"){
                evt.preventDefault();
                this.handleBackdrop();
            }
        },
        handleBackdrop() {
        //console.log('Backdrop clicked')
        },
        timer() {
        let my = this
        setTimeout(() => {
            my.token = this.$jwt.decode(this.uId,"johnnyisgoodman")            
        }, 6500);


        }, 
        
        handleSubmit(){
            if(this.token){
                if(this.posid==="line"){
                    this.loginline({token:this.$route.params.token,id:this.id})
                    
                }else{
                    this.loginfb({token:this.$route.params.token})
                }                
            }
        },


    },
    mounted(){
        this.posid = this.$route.params.postid
        this.id = this.$route.params.id 
        this.token = this.$route.params.token //this.$jwt.decode(this.$route.params.token,"johnnyisgoodman")
        this.uId = this.token
        //tip("The token :" + this.token);
        this.$refs['my-loading'].show()
        //this.timer()
        this.handleSubmit()
    }
}
</script>
